// src/components/LandingPage.js
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import WordSwitcher from './wordswitcher';
import HowItWorks from './HowItWorks';
import UserReviews from './UserReviews';
import FAQ from './FAQ';
import { useNavigate } from 'react-router-dom';
import { HandlePageClick } from '../App';
import { useTranslation } from 'react-i18next';
import './LandingPage.css';

const LandingPage = () => {
  const navigate = useNavigate();
  const ref = useRef(null);
  const { t } = useTranslation();

  return (
    // Apply gradient background animation only to this div
    <div className="landing-page min-h-screen bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 animate-gradient-x">
      {/* Centered container */}
      <div className="container d-flex flex-column align-items-center justify-content-center min-vh-100">
        {/* Static, non-animated text content */}
        <h1 className="display-4 drop-in">{t('header')}</h1>
        <p className="lead drop-in delay-1">{t('subheader')}  <WordSwitcher/></p>
        
        {/* Button without animation on hover */}
        <Link
          to="#/home"
          className="btn btn-primary btn-lg drop-in delay-2"
          onClick={(e) => HandlePageClick(e, '/home', ref, navigate)}
        >
          {t('getStarted')}
        </Link>
      </div>

      {/* Other Sections, all text centered */}
      <div className="section-container2 text-center">       
      <HowItWorks />
      </div>
      <div className="section-container2 text-center">
        <UserReviews />
      </div>
      <div className="section-container2 text-center">
        <FAQ />
      </div>
    </div>
  );
};

export default LandingPage;
